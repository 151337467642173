/* AdminPage.module.css */
.container {
  display: flex;
  background-color: #f7f7f7;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.main-content {
  margin-left: 250px; /* Same width as the sidebar */
  padding: 20px;
  width: calc(100% - 250px);
  transition: margin-left 0.3s, width 0.3s; /* Smooth transition */
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.main-content.sidebar-closed {
  margin-left: 100px; /* Adjust margin when sidebar is closed */
  width: calc(100% - 100px); /* Adjust width when sidebar is closed */
  padding: 20px;
  transition: margin-left 0.3s, width 0.3s; /* Smooth transition */
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.pagination button:disabled {
  opacity: 0.5; /* Style disabled buttons */
}

.pagination input {
  width: 3ch; /* Width of input box */
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #007bff;
}

/* Welcome user section */
.welcomeUser {
  position: fixed;
  top: 1vh;
  right: 2vw;
  color: rgb(242, 144, 144);
}

/* Menu dropdown */
.menu {
  position: fixed;
  top: 10vh;
  right: 2vw;
  color: rgb(253, 253, 253);
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  background-color: rgb(52, 50, 50); /* Added background color */
}

.menu a {
  text-decoration: none;
  color: rgb(253, 253, 253);
}

.menu-profile {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
}

.menu-profile:hover {
  background-color: rgb(96, 95, 95);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.menu-divider {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px; /* Added padding for better spacing */
}

.menu-divider:hover {
  background-color: rgb(96, 95, 95);
}

/* Profile image */
.profile-image {
  width: 100px;
  border-radius: 5%; /* Circular profile image */
}

.profile-image-header {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: rgb(253, 253, 253);
}

.profile-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
}

.profile-menu button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: none;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
}

.profile-menu button:hover {
  background-color: #f1f1f1;
}

/* Media Queries for Mobile Screens */
@media (max-width: 600px) {
  .main-content {
    margin-left: 65px; /* Adjust margin for smaller screens */
    width: calc(100% - 65px); /* Adjust width for smaller screens */
  }

  .main-content.sidebar-closed {
    margin-left: 30px; /* Adjust margin for smaller screens when sidebar is closed */
    width: calc(100% - 30px); /* Adjust width for smaller screens when sidebar is closed */
  }

  /* Chatbot list columns */
  .chatbot-list-content table thead th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(7)):not(:nth-child(8)),
  .chatbot-list-content table tbody td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(7)):not(:nth-child(8)) {
      display: none;
  }

  /* User list columns */
  .user-list-content table thead th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(6)):not(:nth-child(7)),
  .user-list-content table tbody td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(6)):not(:nth-child(7)) {
      display: none;
  }
}
