/* General container styles */
.container {
    display: flex;
    background-color: #f7f7f7;
    height: fit-content;
    width: 100vw;
}

/* Main content area */
.main-content {
    margin-left: 250px; /* Same width as the sidebar */
    padding: 20px;
    width: calc(100% - 250px);
    transition: margin-left 0.3s, width 0.3s; /* Smooth transition */
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.main-content.sidebar-closed {
    margin-left: 150px; /* Adjust margin when sidebar is closed */
    width: calc(100% - 150px); /* Adjust width when sidebar is closed */
}

/* Registration and login links */
.register {
    position: fixed;
    top: 1vh;
    right: 2vw;
    background-color: #f9f9f9; /* Background color for better contrast */
    padding: 10px; /* Padding around the links */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.register .links {
    display: flex;
    gap: 15px; /* Space between the links */
    margin: 0; /* Remove default margin from <p> */
}

.register a {
    text-decoration: none; /* Remove underline */
    color: #007BFF; /* Link color */
    font-weight: bold; /* Make text bold */
    transition: color 0.3s ease; /* Smooth color transition on hover */
}

.register a:hover {
    color: #014690; /* Darker color on hover */
}

/* Welcome user section */
.welcomeUser {
    position: fixed;
    top: 1vh;
    right: 2vw;
    color: rgb(242, 144, 144);
}

/* Menu dropdown */
.menu {
    position: fixed;
    top: 10vh;
    right: 2vw;
    color: rgb(253, 253, 253);
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
    background-color: rgb(52, 50, 50); /* Added background color */
}

.menu a {
    text-decoration: none;
    color: rgb(253, 253, 253);
}

.menu-profile {
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
}

.menu-profile:hover {
    background-color: rgb(96, 95, 95);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.menu-divider {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px; /* Added padding for better spacing */
}

.menu-divider:hover {
    background-color: rgb(96, 95, 95);
}

/* Profile image */
.profile-image {
    width: 100px;
    border-radius: 5%; /* Circular profile image */
}

.profile-image-header {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50%;
    background-color: rgb(253, 253, 253);
}

/* Error message */
.error-message {
    color: red;
    font-weight: bold; /* Make error messages bold */
}

/* Progress bar */
.progress-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress {
    height: 20px;
    background-color: #007BFF;
    text-align: center;
    line-height: 20px;
    color: white;
}

.snippetContainer {
    overflow: auto; /* Allow scrolling if needed */
  }

.copySnippetButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10; /* Ensures the button is in front */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  } 



/* Responsive design */
@media (max-width: 600px) {
    .register {
        top: 2vh; /* Adjust top position on smaller screens */
        right: 1vw; /* Adjust right position on smaller screens */
        padding: 5px; /* Reduce padding on smaller screens */
    }


    .register .links {
        flex-direction: column; /* Stack links vertically on smaller screens */
        gap: 10px;
    }

    /* Welcome user section */
    .welcomeUser {
        top: 0vh;
    }

    .main-content {
        margin-left: 65px; /* Adjust margin for smaller screens */
        width: calc(100% - 65px); /* Adjust width for smaller screens */
    }

    .main-content.sidebar-closed {
        margin-left: 30px; /* Adjust margin for smaller screens when sidebar is closed */
        width: calc(100% - 30px); /* Adjust width for smaller screens when sidebar is closed */
    }

    /* Chatbot list columns */
    .chatbot-list-content table thead th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(7)),
    .chatbot-list-content table tbody td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(7)) {
        display: none;
    }

    .pageInput {
        width: 50px; /* Adjust the width to your desired value */
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }

    .pagination button {
    display: none; /* Hide all buttons by default */
    }

    .pagination .prev-page,
    .pagination .pageInput,
    .pagination .last-page {
    display: inline-block; /* Show only previous, input, and last buttons */
    }
    
}
