/* General container styles */
.registration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    padding: 20px;
}

/* Form styling */
.registration .registrationForm {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center; /* Center-align text inside the form */
}

.registration .registrationForm label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
    text-align: left; /* Keep labels left-aligned */
}


.registration .registrationForm input {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.registration .registrationForm button {
    margin-top: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Make button full width */
}

.registration .registrationForm button:hover {
    background-color: #0056b3;
    box-shadow: 0 0 10px rgba(0, 91, 187, 0.6);
}

.registration .registrationForm button:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 91, 187, 0.6);
}

.registration .registrationForm button:active {
    background-color: #004080;
    box-shadow: 0 0 5px rgba(0, 64, 128, 0.6);
}

.registration .registrationForm .registerLink {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    color: #333;
}

.registration .registrationForm .registerLink a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.registration .registrationForm .registerLink a:hover {
    text-decoration: underline;
}

/* Responsive design */
@media (max-width: 600px) {
    .registration .registrationForm {
        width: 90%;
        padding: 15px;
    }
}
