/* WeatherPage.module.css */

.app {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.title h1 {
    color: #333;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.IP, .location, .tone {
    margin-bottom: 15px;
}

.IP p, .location p {
    font-size: 16px;
    color: #555;
}

.tone label {
    font-size: 16px;
    color: #333;
    margin-right: 10px;
}

.tone select {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
}

.weather, .suggestion {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.weather h3, .suggestion h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
}

.weather p, .suggestion ul {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
}

.suggestion ul {
    list-style-type: disc;
    padding-left: 20px;
}

.suggestion ul p {
    margin-bottom: 8px;
}

.error {
    margin-top: 20px;
    color: red;
}
