body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
  
}

.app {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.navbar {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
}

.navbar nav ul li {
  display: inline;
}

.navbar nav ul li a {
  text-decoration: none;
  color: black; /* Change this to the color you prefer */
  font-size: 18px;
  padding: 10px 15px;
  transition: background-color 0.1s ease, color 0.1s ease;
}

.navbar nav ul li a:hover {
  background-color: #fefefe; /* Background color on hover */
  color: #3498db; /* Text color on hover */
  border-radius: 10px; /* Adds rounded corners on hover */
}


.hero {
  background: linear-gradient(135deg, #abb4c0,#e8ecf0, #abb3bd);
  color: #ffffff;
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 0px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;               /* Enable flexbox */
  justify-content: center;      /* Horizontally center content */
  align-items: center;          /* Vertically center content (optional) */
  flex-direction: column;       /* Stack elements vertically */
}


.hero {
  padding: 4rem 1rem;
  color: #141415;

}

.hero h1 {
  font-size: 3rem;
  font-weight: bold;
  width: 62%;                  /* Make it 100% width for consistent centering */
  text-align: center;           /* Ensure text within the h1 is centered */
}

.hero p {
  font-size: 1rem;
  font-weight: bold;
  width: 80%;                  /* Make it 100% width for consistent centering */
  text-align: center;           /* Ensure text within the h1 is centered */
}


.hero h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  color: #141414;
  font-family: 'Poppins', sans-serif;
}

.gradient-text {
  font-size: 3.5rem; /* Larger font for emphasis */
  font-weight: bold;
  background: linear-gradient(90deg, #2979ff, #8e24aa, #ff4081); /* Blue to purple to pink gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
}

.gradient-text::after {
  content: '✨';
  position: absolute;
  top: -10px;
  right: -25px;
  font-size: 1.8rem;
  color: #ffc107; /* Yellow star color */
}



.cta-buttons {
  display: inline-flex;
  gap: 1rem;
  margin-top: 2rem;
}

.outline-btn {
  background: transparent;
  color: #044071;
  border: 2px solid #ff4081; /* Pink border */
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.outline-btn:hover {
  background: rgba(255, 64, 129, 0.2); /* Pink hover effect */
  color: #ffffff;
}

.filled-btn {
  background-color: #ffc107; /* Yellow background */
  color: #333;
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.filled-btn:hover {
  background-color: #ff4081; /* Pink hover background */
  color: #ffffff;
}

.cta-button {
  padding: 0.75rem 2rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-text {
  font-size: 1.2rem;
  color: #0b0b0b;
  margin-top: 2rem;
  max-width: 800px;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif;
  margin: 2rem auto 0;
}







.cta-button {
  padding: 1rem 2rem;
  background-color: white;
  color: #007BFF;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #0056b3;
  color: white;
}

.about {
  padding: 3rem 1.5rem;
  background: linear-gradient(135deg, #0d253f, #1b3b5f);
  color: #ffffff;
  text-align: center;
  border-radius: 0px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(45deg, #03a9f4, #ff4081);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
}

.about p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #dcdcdc;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  text-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
}

.about:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
}

.about:hover h2 {
  background: linear-gradient(45deg, #ff4081, #448aff);
  -webkit-background-clip: text;
  color: transparent;
}

.about p::before {
  content: '“';
  font-size: 3rem;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.3);
  margin-right: 0.3rem;
}

.about p::after {
  content: '”';
  font-size: 3rem;
  vertical-align: bottom;
  color: rgba(255, 255, 255, 0.3);
  margin-left: 0.3rem;
}


.chatbot-cards {
  width: 80%; /* Make the container 80% of the screen width */
  margin: 0 auto; /* Center the container */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr)); /* Ensure dynamic column arrangement */
  gap: 16px; /* Space between cards */
  justify-items: center;
}

.chatbot-card-link {
  text-decoration: none;
  color: inherit;
}

.chatbot-card {
  width: 100%; /* Card width will dynamically adjust to the column size */
  max-width: 150px; /* Set a maximum width to avoid overly large cards */
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chatbot-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.chatbot-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.chatbot-tags {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 8px 0;
}

.tag {
  background-color: #ff5f5f;
  color: white;
  font-size: 0.7em;
  padding: 4px 8px;
  border-radius: 12px;
}

.chatbot-name {
  font-weight: bold;
  font-size: 1.1em;
  margin: 8px 0 4px;
}

.chatbot-date {
  font-size: 0.8em;
  color: #757575;
}

.chatbot-rating {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.star {
  font-size: 1.2em;
  color: #FFD700;
}

.star.half {
  color: #C0C0C0;
}



.features {
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
  color: #fff;
  padding: 3rem 1.5rem;
  text-align: center;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
}

.feature-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.feature-item {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 350px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-item h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(45deg, #03a9f4, #e91e63);
  -webkit-background-clip: text;
  color: transparent;
}

.feature-item p {
  font-size: 1.1rem;
  color: #dcdcdc;
  line-height: 1.6;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
}

.feature-item:hover h3 {
  background: linear-gradient(45deg, #ff4081, #448aff);
  -webkit-background-clip: text;
  color: transparent;
}

.feature-item:hover p {
  color: #ffffff;
}

.feature-item::before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  background: linear-gradient(45deg, #ff4081, #448aff);
  filter: blur(15px);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 15px;
}

.feature-item:hover::before {
  opacity: 1;
}



.video-section {
  padding: 0rem 0rem;
  background: linear-gradient(135deg, #b3bcc7,#e8ecf0, #b3bcc7);
  width: 100%;
}

.video-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.video-item {
  width: 100%;
  /* background: linear-gradient(135deg, #b3bcc7,#e8ecf0, #b3bcc7); */
  padding: 0rem;
  border-radius: 0px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  margin: 0rem;
}




.pricing {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: #ffffff;
  text-align: center;
  border-radius: 0px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.pricing h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.priceValue {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff4081;
  margin-top: 1rem;
}

.pricing-table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.pricing-option {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(7, 7, 7, 0.3);
  text-align: left;
  flex-basis: 30%;
  transition: all 0.3s ease;
  position: relative;
}

.pricing-option:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
}

.pricing-option h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #201f1f;
  margin-bottom: 1rem;
}

.pricing-option p {
  font-size: 1.2rem;
  color: #1f1f1f;
  margin-bottom: 1rem;
}

.pricing-option ul {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 1rem;
}

.pricing-option ul li {
  font-size: 1rem;
  margin-bottom: 0.8rem;
  color: #1e1e1e;
  display: flex;
  align-items: center;
}

.pricing-option ul li::before {
  content: '✔';
  color: #ff4081;
  font-weight: bold;
  margin-right: 10px;
}

.pricing-option button {
  padding: 0.75rem 2rem;
  background: linear-gradient(135deg, #ff4081, #448aff);
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
}

.pricing-option button:hover {
  background-color: #ff4081;
}

/* Plan Specific Borders and Gradients */
.pricing-option:nth-child(1) {
  border-top: 6px solid #ffa500;
  background: linear-gradient(135deg, #fbf6d2, #f9f8f8);
}

.pricing-option:nth-child(2) {
  border-top: 6px solid #f33e93;
  background: linear-gradient(135deg, #FFF0F7, #FFFFFF);
}

.pricing-option:nth-child(3) {
  border-top: 6px solid #A100FF;
  background: linear-gradient(135deg, #ddb0f8, #fdf7f7);
}


/* Responsive Design */
@media (max-width: 768px) {
  .pricing-table {
    flex-direction: column;
    align-items: center;
  }

  .pricing-option {
    width: 90%;
    margin-bottom: 20px;
  }
}


.footer {
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: #ffffff;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.footer-info {
  max-width: 800px;
}

.footer-info p {
  margin: 0.5rem 0;
  font-size: 1rem;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: color 0.3s ease;
}

.footer-info a {
  color: #03a9f4;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.footer-info a:hover {
  color: #ff4081;
}

.footer-info p:last-child {
  font-size: 0.9rem;
  color: #dcdcdc;
}

.footer:hover {
  transform: scale(1.02);
  box-shadow: 0 -8px 30px rgba(0, 0, 0, 0.5);
}

.footer:hover .footer-info p, .footer-info a {
  color: #ffffff;
}

.footer-info::before {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #03a9f4;
  margin: 1rem auto;
}

.footer-info::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #ff4081;
  margin: 1rem auto;
}


/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
  }

  .navbar ul li {
    margin: 1rem 0;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  .feature-list {
    flex-direction: column;
  }

  .pricing-table {
    flex-direction: column;
  }

  .pricing-option {
    width: 100%;
    margin: 1rem 0;
  }
  
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .video-item {
    width: 100%;
  }
  .chatbot-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-items: center;
  }
  .chatbot-card {
    width: 10px;
    /* background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; */
  }
}
