/* Reset some default styles */

.App {
  width: 100%;
  margin: 0 auto;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  height: 10vh;
  padding: 10px 10px;
  background-color: #201f1f;
  z-index: 10;
}
.App-logo {
  height: 12vmin;
  pointer-events: none;
}

.DrYangAIlogo {
  position: absolute;
  height: 20vmin;
  top: -4vh;
  left: -2vw;
  z-index: 100; /* Add this line to bring it to the top */
}
.App-title {
  position: absolute;
  left: 12vw;
  color: rgb(206, 208, 209); /* Initial color */
  font-weight: bold;
  background: linear-gradient(90deg, #2979ff, #8e24aa); /* Blue to purple gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none; /* Remove underline */
  z-index: 100;
}


.slogan {
  position: relative;
  font-size:x-large;
  top: 3.8vh;
  left: 35vw;
  color: rgb(206, 208, 209); /* Initial color */
  text-decoration: none; /* Remove underline */
  z-index: 1;
}

.title-text {
  color: rgb(175, 176, 177); /* Initial color */
  text-decoration: none; /* Remove underline */
}

.hover-text {
  cursor: pointer;
  color: rgb(175, 176, 177); /* Initial color */
  text-decoration: none; /* Remove underline */
}

.hover-text:hover p {
  color: rgb(4, 117, 198) !important; /* Color on hover with higher specificity and !important */
  text-decoration: none !important; /* Ensure underline is not added on hover */
}

.App-link {
  color: #61dafb;
}


.App-footer {
  background-color: grey;
  color: white;
  height: 5vh; /* 10% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-logo {
    width: 100px;
    height: auto;
    animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}


/* Media query for small devices (like phones) */
@media  only screen and (max-width: 768px) {
  .App-header {
    height: 6vh;
    padding: 5px 5px;
  }

  .App {
    width: 100%;
  }
  .App-title {
    position: absolute;
    left: 32vw;
    top: 0px;
    color: rgb(206, 208, 209); /* Initial color */
    text-decoration: none; /* Remove underline */
  }
  .DrYangAIlogo {
    height: 20vmin;
    top: -2vh;
  }
  .slogan {
    position: relative;
    top: 6vh;
    left: 25vw;
    font-size: smaller;
    color: rgb(90, 91, 92); /* Initial color */
    text-decoration: none; /* Remove underline */
    z-index: 1;
  }
  
  .App-footer {
    background-color: grey;
    color: white;
    height: 5vh; /* 10% of the viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
  }

}