/* General styling for the login page */
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
  }
  
  .loginForm {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center; /* Center-align text inside the form */
  }
  
  .loginForm label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
    text-align: left; /* Keep labels left-aligned */
  }
  
  .loginForm input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .loginButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .loginButton:hover {
    background-color: #0056b3;
  }
  
  .loginForm p {
    text-align: center;
  }
  
  .loginForm a {
    color: #007bff;
    text-decoration: none;
  }
  
  .loginForm a:hover {
    text-decoration: underline;
  }
  