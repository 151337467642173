.input-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .dollar-sign {
    position: absolute;
    left: 10px; /* Adjust this value as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Make the dollar sign not clickable */
    color: grey; /* Set the color to grey */
}
  
  .rounded-input {
    padding-left: 20px; /* Adjust this value as needed to make space for the dollar sign */
    border-radius: 15px; /* Adjust the value to control the roundness */
    border: 1px solid #ccc; /* Optional: Add border color */
    outline: none; /* Optional: Remove outline */
    height: 50px; /* Adjust the value to control the height */
  }

  .error-text {
    color: red;
  }
  
  .normal-text {
    color: black;
  }