/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  /* Green background for the switch when checked */
  .slider.green {
    background-color: green;
  }
  
  /* Move the slider to the right when checked */
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  

  .chatbot-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
  }
  
  .chatbot-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 15px;
    background: #fff;
    text-align: center;
  }
  
  .chatbot-card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .chatbot-card-content h3 {
    margin: 10px 0;
    font-size: 18px;
    color: #333;
  }
  
  .chatbot-card-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  