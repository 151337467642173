/* Sidebar Styles */
.sidebar {
  height: 100vh;
  width: 200px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #111;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
}

.sidebar.closed {
  width: 70px;
}

.sidebar .sidebarContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.sidebar .toggleButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #444;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.sidebar .toggleButton:hover {
  color: #bbb;
  background-color: #333;
}

.sidebar button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
  margin: 0;  /* Ensure no margin is adding extra space */
}

.sidebar button:hover {
  background-color: #575757;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 10px;
  overflow: auto; /* Add scrolling for overflow content */
}

.sidebar .menuButton .icon {
  margin-right: 10px;
}

.sidebar.closed .icon {
  margin-right: 0;
}

/* Media Queries for Mobile Screens */
@media (max-width: 600px) {
  .sidebar {
    width: 70px;
  }

  .sidebar.closed {
    width: 30px;
  }

  .sidebar .toggleButton {
    bottom: 10px;
    right: 5px;
    font-size: 12px;
    padding: 5px;
  }

  .sidebar .menuButton {
    padding: 10px;
    text-align: center;
  }

  .sidebar button span {
    display: none;
  }
}
